import Vue from 'vue'
import App from './App.vue'
import router from './router'

import vueRouter from "vue-router";
import './styles/common.less'
import './styles/style.less'
Vue.use(vueRouter)
new Vue({
    render: h => h(App),
    router

}).$mount('#app')