import VueRouter from 'vue-router'
// import App from "../App"
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/about.vue'),
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/contact.vue'),
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/privacy.vue'),
    },
    {
        path: '/privacy-jiaban-social',
        name: 'privacy-jiaban-social',
        component: () => import('@/views/privacy-jiaban-social.vue'),
    },
    {
        path: '/privacy-jiaban-software',
        name: 'privacy-jiaban-social',
        component: () => import('@/views/privacy-jiaban-software.vue'),
    },
    {
        path: '/protocol',
        name: 'protocol',
        component: () => import('@/views/protocol.vue')
    },
    {
        path: '/protocol-jiaban-social',
        name: 'protocol-jiaban-social',
        component: () => import('@/views/protocol-jiaban-social.vue')
    },
    {
        path: '/protocol-jiaban-software',
        name: 'protocol-jiaban-software',
        component: () => import('@/views/protocol-jiaban-software.vue')
    },
    {
        path: '/exchange',
        name: 'exchange',
        component: () => import('@/views/exchange.vue')
    },
    {
        path: '/rechargePolicy',
        name: 'rechargePolicy',
        component: () => import('@/views/rechargePolicy.vue')
    },
    {
        path: '/article',
        name: 'ArticleLists',
        component: () => import('@/views/article/lists.vue')
    },
    {
        path: '/content/:id/:type',
        name: 'ArticleContent',
        component: () => import('@/views/article/content.vue')
    },

]

const router = new VueRouter({
    // 配置URL和组价直接的映射关系
    routes,
    // history模式
    mode: 'hash'
})
export default router